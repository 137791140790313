<template>
  <div class="add-new-holiday">
    <title-bar
      submitBtn
      :submitBtnTitle="$t('messages.employeeCreate')"
      :title="$t('messages.employeeCreate')"
      @submitPressed="submitPressed"
    />
    <employee-form :employeeObject="employee" :action="action" @clearAction="action = null"/>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import EmployeeForm from './components/EmployeeForm.vue'

export default {
  components: {
    TitleBar,
    EmployeeForm
  },
  data() {
    return {
      action: null,
      employee: {
        role: "ROLE_EMPLOYEE",
        address: {}
      }
    }
  },
  methods: {
    submitPressed() {
      this.action = 'add'
    }
  }
}
</script>
