<template>
  <div class="content-box">
    <b-form @submit.prevent="onSubmit">
      <b-card no-body>
        <b-tabs card content-class="mt-3">
          <b-tab :title="$t('forms.basicInfo')" active>
            <b-row>
              <b-col cols-lg="6">
                <b-form-group
                  :class="{ row, error: v$.employee.number.$errors.length }"
                  :label="$t('forms.number')"
                  label-for="number"
                  label-cols-md="4"
                  content-cols-lg="8"
                >
                  <b-form-input v-model="employee.number" id="number" />
                  <div
                    class="input-errors"
                    v-for="error of v$.employee.number.$errors"
                    :key="error.$uid"
                  >
                    <small class="text-danger">{{ error.$message }}</small>
                  </div>
                </b-form-group>
                <b-form-group
                  :class="{ row, error: v$.employee.givenName.$errors.length }"
                  :label="$t('forms.givenName')"
                  label-for="givenName"
                  label-cols-md="4"
                  content-cols-lg="8"
                >
                  <b-form-input v-model="employee.givenName" id="givenName" />
                  <div
                    class="input-errors"
                    v-for="error of v$.employee.givenName.$errors"
                    :key="error.$uid"
                  >
                    <small class="text-danger">{{ error.$message }}</small>
                  </div>
                </b-form-group>
                <b-form-group
                  :class="{ row, error: v$.employee.familyName.$errors.length }"
                  :label="$t('forms.familyName')"
                  label-for="familyName"
                  label-cols-md="4"
                  content-cols-lg="8"
                >
                  <b-form-input v-model="employee.familyName" id="familyName" />
                  <div
                    class="input-errors"
                    v-for="error of v$.employee.familyName.$errors"
                    :key="error.$uid"
                  >
                    <small class="text-danger">{{ error.$message }}</small>
                  </div>
                </b-form-group>
                <b-form-group
                  class="row"
                  :label="$t('forms.title')"
                  label-for="title"
                  label-cols-md="4"
                  content-cols-lg="8"
                >
                  <b-form-input v-model="employee.title" id="title" />
                </b-form-group>

                <b-form-group
                  :class="{
                    row,
                    error: v$.contractSelect.$errors.length,
                  }"
                  :label="$t('forms.contract')"
                  label-for="contractSelect"
                  label-cols-md="4"
                  content-cols-lg="8"
                  v-if="$helper.userCapability('UPDATE','EMPLOYEES')"
                >
                  <v-select
                    id="contractSelect"
                    v-model="contractSelect"
                    :options="contracts"
                  />
                  <div
                    class="input-errors"
                    v-for="error of v$.contractSelect.$errors"
                    :key="error.$uid"
                  >
                    <small class="text-danger">{{ error.$message }}</small>
                  </div>
                </b-form-group>
                <b-form-group
                  :class="{
                    row,
                    error: v$.departmentSelect.$errors.length,
                  }"
                  :label="$t('forms.department')"
                  label-for="departmentSelect"
                  label-cols-md="4"
                  content-cols-lg="8"
                >
                  <v-select
                    id="departmentSelect"
                    v-model="departmentSelect"
                    :options="departments"
                  />
                  <div
                    class="input-errors"
                    v-for="error of v$.departmentSelect.$errors"
                    :key="error.$uid"
                  >
                    <small class="text-danger">{{ error.$message }}</small>
                  </div>
                </b-form-group>
                <b-form-group
                    class="row"
                    :label="$t('forms.itemCategoryDepartment')"
                    label-for="itemCategoriesSelect"
                    label-cols-md="4"
                    content-cols-lg="8"
                >
                  <v-select
                      id="itemCategoriesSelect"
                      v-model="itemCategoriesSelect"
                      :options="itemCategories"
                      multiple="true"
                  />
                </b-form-group>
                <b-form-group
                  :class="{
                    row,
                    error: v$.jobSelect.$errors.length,
                  }"
                  :label="$t('forms.jobTitle')"
                  label-for="jobSelect"
                  label-cols-md="4"
                  content-cols-lg="8"
                >
                  <v-select
                    id="jobSelect"
                    v-model="jobSelect"
                    :options="jobs"
                  />
                  <div
                    class="input-errors"
                    v-for="error of v$.jobSelect.$errors"
                    :key="error.$uid"
                  >
                    <small class="text-danger">{{ error.$message }}</small>
                  </div>
                </b-form-group>
                <b-form-group
                  class="row"
                  :label="$t('forms.employeeType')"
                  label-for="typeSelect"
                  label-cols-md="4"
                  content-cols-lg="8"
                >
                  <v-select
                    id="typeSelect"
                    v-model="typeSelect"
                    :options="types"
                  />
                </b-form-group>
                <b-form-group
                  class="row"
                  :label="$t('forms.replacement')"
                  label-for="replacement"
                  label-cols-md="4"
                  content-cols-lg="8"
                >
                  <b-form-input
                    v-model="employee.replacement"
                    id="replacement"
                  />
                </b-form-group>
                <b-form-group
                  class="row"
                  :label="$t('forms.shift')"
                  label-for="shiftSelect"
                  label-cols-md="4"
                  content-cols-lg="8"
                >
                  <v-select
                    id="shiftSelect"
                    v-model="shiftSelect"
                    :options="shifts"
                  />
                </b-form-group>
                <b-form-group
                  class="row"
                  :label="$t('forms.education')"
                  label-for="education"
                  label-cols-md="4"
                  content-cols-lg="8"
                  v-if="$helper.userCapability('UPDATE','EMPLOYEES')"
                >
                <v-select
                    id="education"
                    v-model="educationSelect"
                    :options="educations"
                  />
                </b-form-group>
                <b-form-group
                  class="row"
                  :label="$t('forms.phone')"
                  label-for="telephone"
                  label-cols-md="4"
                  content-cols-lg="8"
                  v-if="$helper.userCapability('UPDATE','EMPLOYEES')"
                >
                  <b-form-input v-model="employee.telephone" id="telephone" />
                </b-form-group>
                <b-form-group
                  class="row"
                  :label="$t('forms.businessPhone')"
                  label-for="businessPhone"
                  label-cols-md="4"
                  content-cols-lg="8"
                >
                  <b-form-input
                    v-model="employee.businessPhone"
                    id="businessPhone"
                  />
                </b-form-group>
                <b-form-group
                  class="row"
                  :label="$t('forms.trialPeriodEmailSent')"
                  label-for="trialPeriodEmailSent"
                  label-cols-md="4"
                  content-cols-lg="8"
                >
                  <b-form-checkbox v-model="employee.trialPeriodEmailSent" name="trialPeriodEmailSent" switch/>
                </b-form-group>
              </b-col>
              <b-col cols-lg="6">
                <b-form-group
                  :class="{
                    row,
                    error: v$.employee.address.streetAddress.$errors.length,
                  }"
                  :label="$t('forms.address')"
                  label-for="address"
                  label-cols-md="4"
                  content-cols-lg="8"
                  v-if="$helper.userCapability('UPDATE','EMPLOYEES')"
                >
                  <b-form-input
                    v-model="employee.address.streetAddress"
                    id="address"
                  />
                  <div
                    class="input-errors"
                    v-for="error of v$.employee.address.streetAddress.$errors"
                    :key="error.$uid"
                  >
                    <small class="text-danger">{{ error.$message }}</small>
                  </div>
                </b-form-group>

                <b-form-group
                  :class="{
                    row,
                    error: v$.employee.address.city.$errors.length,
                  }"
                  :label="$t('forms.city')"
                  label-for="city"
                  label-cols-md="4"
                  content-cols-lg="8"
                  v-if="$helper.userCapability('UPDATE','EMPLOYEES')"
                >
                  <b-form-input v-model="employee.address.city" id="city" />
                  <div
                    class="input-errors"
                    v-for="error of v$.employee.address.city.$errors"
                    :key="error.$uid"
                  >
                    <small class="text-danger">{{ error.$message }}</small>
                  </div>
                </b-form-group>
                <b-form-group
                  :class="{
                    row,
                    error: v$.employee.address.postalCode.$errors.length,
                  }"
                  :label="$t('forms.postalCode')"
                  label-for="postalCode"
                  label-cols-md="4"
                  content-cols-lg="8"
                  v-if="$helper.userCapability('UPDATE','EMPLOYEES')"
                >
                  <b-form-input
                    v-model="employee.address.postalCode"
                    id="postalCode"
                  />
                  <div
                    class="input-errors"
                    v-for="error of v$.employee.address.postalCode.$errors"
                    :key="error.$uid"
                  >
                    <small class="text-danger">{{ error.$message }}</small>
                  </div>
                </b-form-group>
                <b-form-group
                  :class="{
                    row,
                    error: v$.employee.address.country.$errors.length,
                  }"
                  :label="$t('forms.country')"
                  label-for="country"
                  label-cols-md="4"
                  content-cols-lg="8"
                >
                  <b-form-input
                    v-model="employee.address.country"
                    id="country"
                  />
                  <div
                    class="input-errors"
                    v-for="error of v$.employee.address.country.$errors"
                    :key="error.$uid"
                  >
                    <small class="text-danger">{{ error.$message }}</small>
                  </div>
                </b-form-group>
                <b-form-group
                    class="row"
                    :label="$t('forms.alternativeAddress')"
                    label-for="alternativeAddress"
                    label-cols-md="4"
                    content-cols-lg="8"
                >
                  <b-form-input v-model="employee.alternativeAddress" id="alternativeAddress" />
                  <div>
                    <small>{{ $t('forms.alternativeAddressInfo') }}</small>
                  </div>
                </b-form-group>
                <b-form-group
                  class="row"
                  :label="$t('forms.personalId')"
                  label-for="personalId"
                  label-cols-md="4"
                  content-cols-lg="8"
                  v-if="$helper.userCapability('UPDATE','EMPLOYEES')"
                >
                  <b-form-input v-model="employee.personalId" id="personalId" />
                </b-form-group>
                <b-form-group
                  :label="$t('forms.email')"
                  label-for="email"
                  label-cols-md="4"
                  content-cols-lg="8"
                  v-if="$helper.userCapability('UPDATE','EMPLOYEES')"
                >
                  <b-form-input v-model="employee.email" id="email" />
                </b-form-group>
                <b-form-group
                    class="row"
                    :label="$t('forms.password')"
                    label-for="password"
                    label-cols-md="4"
                    content-cols-lg="8"
                >
                  <b-form-input
                      v-model="employee.plainPassword"
                      id="password"
                  />
                </b-form-group>
                <b-form-group
                  class="row"
                  :label="$t('forms.category')"
                  label-for="category"
                  label-cols-md="4"
                  content-cols-lg="8"
                  v-if="$helper.userCapability('UPDATE','EMPLOYEES')"
                >
                  <b-form-input v-model="employee.category" id="category" />
                </b-form-group>
                <b-form-group
                  :class="{ row, error: v$.employee.birthdate.$errors.length }"
                  :label="$t('forms.birthdate')"
                  label-for="h-birthdate"
                  label-cols-md="4"
                  content-cols-lg="8"
                  v-if="$helper.userCapability('UPDATE','EMPLOYEES')"
                >
                  <datepicker
                    id="birthdate"
                    v-model="employee.birthdate"
                    :enableTimePicker="false"
                    class="form-input"
                    format="dd. MM. yyyy"
                    previewFormat="dd. MM. yyyy"
                  />
                  <div
                    class="input-errors"
                    v-for="error of v$.employee.birthdate.$errors"
                    :key="error.$uid"
                  >
                    <small class="text-danger">{{ error.$message }}</small>
                  </div>
                </b-form-group>
                <b-form-group
                  class="row"
                  :label="$t('forms.employmentDate')"
                  label-for="employmentDate"
                  label-cols-md="4"
                  content-cols-lg="8"
                >
                  <datepicker
                    id="employmentDate"
                    v-model="employee.employmentDate"
                    :enableTimePicker="false"
                    class="form-input"
                    format="dd. MM. yyyy"
                    previewFormat="dd. MM. yyyy"
                  />
                </b-form-group>
                <b-form-group
                  class="row"
                  :label="$t('forms.leavingDate')"
                  label-for="leavingDate"
                  label-cols-md="4"
                  content-cols-lg="8"
                >
                  <datepicker
                    id="leavingDate"
                    v-model="employee.leavingDate"
                    :enableTimePicker="false"
                    class="form-input"
                    format="dd. MM. yyyy"
                    previewFormat="dd. MM. yyyy"
                  />
                </b-form-group>
                <b-form-group
                  class="row"
                  :label="$t('forms.endDateOfContract')"
                  label-for="endDateOfContract"
                  label-cols-md="4"
                  content-cols-lg="8"
                >
                  <datepicker
                    id="endDateOfContract"
                    v-model="employee.endDateOfContract"
                    :enableTimePicker="false"
                    class="form-input"
                    format="dd. MM. yyyy"
                    previewFormat="dd. MM. yyyy"
                  />
                </b-form-group>

                <b-form-group
                  class="row"
                  :label="$t('forms.idCard')"
                  label-for="idCard"
                  label-cols-md="4"
                  content-cols-lg="8"
                  v-if="$helper.userCapability('UPDATE','EMPLOYEES')"
                >
                  <b-form-input v-model="employee.idCard" id="idCard" />
                </b-form-group>
                <b-form-group
                  class="row"
                  :label="$t('forms.status')"
                  label-for="status"
                  label-cols-md="4"
                  content-cols-lg="8"
                >
                  <v-select
                    id="status"
                    v-model="statusSelect"
                    :options="statuses"
                  />
                </b-form-group>
                <b-form-group
                  class="row"
                  :label="$t('forms.printStatus')"
                  label-for="printStatus"
                  label-cols-md="4"
                  content-cols-lg="8"
                >
                  <v-select
                    id="printStatus"
                    v-model="printStatusSelect"
                    :options="printStatuses"
                  />
                </b-form-group>
                <b-row>
                  <b-col lg="4">
                    <label for="">{{ $t('forms.photo') }}</label>
                  </b-col>
                  <b-col lg="8">
                    <image-upload :preview-image="fileURL" @fileUploaded="setPhoto"/>
                  </b-col>
                </b-row>


              </b-col>
            </b-row>
          </b-tab>
          <b-tab
              :title="$t('forms.equipment')"
              v-if="$helper.userCapability('UPDATE','EMPLOYEES')"
          >
            <b-form-group
              class="row"
              :label="$t('forms.tshirtSize')"
              label-for="tshirtSize"
              label-cols-md="4"
              content-cols-lg="8"
            >
              <v-select
                id="tshirtSize"
                v-model="tshirtSizeSelect"
                :options="sizes"
              />
            </b-form-group>
            <b-form-group
              class="row"
              :label="$t('forms.tshirt')"
              label-for="tshirt"
              label-cols-md="4"
              content-cols-lg="8"
            >
              <b-form-input
                v-model="employee.tshirt"
                type="number"
                id="tshirt"
              />
            </b-form-group>
            <b-form-group
              class="row"
              :label="$t('forms.longTshirt')"
              label-for="longTshirt"
              label-cols-md="4"
              content-cols-lg="8"
            >
              <b-form-input
                v-model="employee.longTshirt"
                type="number"
                id="longTshirt"
              />
            </b-form-group>
            <b-form-group
              class="row"
              :label="$t('forms.vest')"
              label-for="vest"
              label-cols-md="4"
              content-cols-lg="8"
            >
              <b-form-input
                v-model="employee.vest"
                type="number"
                id="vest"
              />
            </b-form-group>
            <b-form-group
              class="row"
              :label="$t('forms.shoeSize')"
              label-for="shoeSize"
              label-cols-md="4"
              content-cols-lg="8"
            >
              <b-form-input
                v-model="employee.shoeSize"
                type="number"
                id="shoeSize"
              />
            </b-form-group>
            <b-form-group
              class="row"
              :label="$t('forms.workShoes')"
              label-for="workShoes"
              label-cols-md="4"
              content-cols-lg="8"
            >
              <b-form-input
                v-model="employee.workShoes"
                type="number"
                id="workShoes"
              />
            </b-form-group>
            <b-form-group
              class="row"
              :label="$t('forms.bag')"
              label-for="bag"
              label-cols-md="4"
              content-cols-lg="8"
            >
              <b-form-input v-model="employee.bag" type="number" id="bag" />
            </b-form-group>
          </b-tab>
          <b-tab :title="$t('forms.skills')">
            <skills :employeeObject="employee" />
          </b-tab>
        </b-tabs>
      </b-card>
    </b-form>
  </div>
</template>

<script>
import moment from "moment";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
import vSelect from "vue-select";
import Skills from "./Skills.vue";
import ImageUpload from "@/components/Inputs/ImageUpload.vue";

export default {
  components: {
    Datepicker,
    vSelect,
    Skills,
    ImageUpload,
  },
  props: {
    employeeObject: { Object, required: true },
    action: { type: String, default: null },
  },
  mixins: [ResourceUtils, ListUtils],
  created() {
      this.getEntityOptionList(['SHIFT', 'CONTRACT', 'JOB', 'EMPLOYEE_TYPE', 'DEPARTMENT', 'STATUS', 'TSHIRT_SIZE'], this.shifts, this.contracts, this.jobs, this.types, this.departments, this.statuses, this.sizes)
      this.getEnumList(['PRINT_STATUS', 'EDUCATION_LEVEL', 'ITEM_CATEGORY'], this.printStatuses, this.educations, this.itemCategories)
      this.statusSelect = this.employee.statusSelect
      this.shiftSelect = this.employee.shiftSelect
      this.printStatusSelect = this.employee.printStatusSelect
      this.contractSelect = this.employee.contractSelect
      this.jobSelect = this.employee.jobSelect
      this.departmentSelect = this.employee.departmentSelect
      this.typeSelect = this.employee.typeSelect
      this.tshirtSizeSelect = this.employee.tshirtSizeSelect
      this.educationSelect = this.employee.educationSelect
      this.itemCategoriesSelect = this.employee.itemCategoriesSelect
      if (this.employee && this.employee.fileUrl) {
        this.fileURL = this.employee.fileUrl;
      }
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      contractSelect: { required },
      departmentSelect: { required },
      jobSelect: { required },
      employee: {
        number: { required },
        givenName: { required },
        familyName: { required },
        address: {
          streetAddress: { required },
          city: { required },
          postalCode: { required },
          country: { required },
        },
        birthdate: { required },
      },
    };
  },
  data() {
    return {
      employee: this.employeeObject,
      departments: [],
      departmentSelect: {},
      jobs: [],
      jobSelect: {},
      contracts: [],
      contractSelect: {},
      types: [],
      typeSelect: {},
      shifts: [],
      shiftSelect: {},
      statuses: [],
      statusSelect: {},
      printStatuses: [],
      printStatusSelect: {},
      tshirtSizeSelect: {},
      educationSelect: {},
      itemCategoriesSelect: [],
      itemCategories: [],
      educations: [],
      sizes: [],
      fileURL: null
    };
  },
  watch: {
    action() {
      this.onSubmit();
    },
  },
  methods: {
    setPhoto(data) {
      if ('file' in this.employee) {
        this.deleteByUrl(this.$Files, this.employee.file, null);
      }
      this.employee.file = data["@id"];
      this.fileURL = data.url;
      const body = {
        file: this.employee.file
      }
      this.update(this.$Users, this.employee.id, body, this.$t('messages.employeeUpdated'));
    },
    async onSubmit() {
      const isValid = await this.v$.$validate();
      if (isValid) {

          const body = this.employee
          if (!body.email || body.email.trim() === '') {
              body.email = null;
          }

          if (body.birthdate) {
            body.birthdate = moment(body.birthdate).format();
          } else {
            body.birthdate = null
          }

          if (body.leavingDate) {
            body.leavingDate = moment(body.leavingDate).format();
          } else {
            body.leavingDate = null
          }

          if (body.endDateOfContract) {
            body.endDateOfContract = moment(body.endDateOfContract).format();
          } else {
            body.endDateOfContract = null
          }

          if (body.employmentDate) {
            body.employmentDate = moment(body.employmentDate).format();
          } else {
            body.employmentDate = null
          }

          if (this.tshirtSizeSelect) {
            body.tshirtSize = this.tshirtSizeSelect.id
            delete body.tshirtSizeSelect
          } else {
            body.tshirtSize = null
          }

          if (this.shiftSelect) {
            body.shift = this.shiftSelect.id
            delete body.shiftSelect
          } else {
            body.shift = null
          }

          if (this.departmentSelect) {
            body.department = this.departmentSelect.id
            delete body.departmentSelect
          } else {
            body.department = null
          }

          if (this.itemCategoriesSelect && this.itemCategoriesSelect.length > 0) {
            body.itemCategories = this.itemCategoriesSelect.map((item) => {
              return item.id
            });
          } else {
            body.itemCategories = []
          }

          if (this.jobSelect) {
            body.job = this.jobSelect.id
            delete body.jobSelect
          } else {
            body.job = null
          }

          if (this.statusSelect) {
            body.status = this.statusSelect.id
            delete body.statusSelect
          } else {
            body.status = null
          }

          if (this.printStatusSelect) {
            body.printStatus = this.printStatusSelect.id
            delete body.printStatusSelect
          } else {
            body.printStatus = null
          }

          if (this.contractSelect) {
            body.contract = this.contractSelect.id
            delete body.contractSelect
          } else {
            body.contract = null
          }

          if (this.typeSelect) {
            body.employeeType = this.typeSelect.id
            delete body.typeSelect
          } else {
            body.employeeType = null
          }

          if (this.educationSelect) {
            body.education = this.educationSelect.id
            delete body.educationSelect
          } else {
            body.education = null
          }

          if (body.tshirt) {
            body.tshirt = parseInt(body.tshirt)
          } else {
            body.tshirt = null
          }

          if (body.longTshirt) {
            body.longTshirt = parseInt(body.longTshirt)
          } else {
            body.longTshirt = null
          }

          if (body.vest) {
            body.vest = parseInt(body.vest)
          } else {
            body.vest = null
          }

          if (body.shoeSize) {
            body.shoeSize = parseInt(body.shoeSize)
          } else {
            body.shoeSize = null
          }

          if (body.workShoes) {
            body.workShoes = parseInt(body.workShoes)
          } else {
            body.workShoes = null
          }

          if (body.bag) {
            body.bag = parseInt(body.bag)
          } else {
            body.bag = null
          }

          if (body.plainPassword && body.plainPassword.trim() !== '') {
            body.plainPassword = body.plainPassword.trim()
          } else {
            body.plainPassword = null
          }

        if (this.action === "add") {
          this.create(
            this.$Users,
            body,
            this.$t("messages.employeeCreated"),
            null,
            this.success,
            this.error
          );
        }
        if (this.action === "update") {
          this.$emit("clearAction");
          this.update(
            this.$Users,
            body.id,
            body,
            this.$t("messages.employeeUpdated"),
            null,
            null,
            this.error
          );
        }
      } else {
        this.$emit("clearAction");
      }
    },
    success() {
      this.$router.push(`/employees`);
    },
    error() {
      this.$emit("clearAction");
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>

