<template>
  <div>
    <div v-if="rows" class="custom-table">
      <table-actions
        :actions="['perPage', 'addBtn']"
        :addBtnText="$t('messages.addNew')"
        :addBtnLink="addLink"
        @onSearchInput="inputChange"
        @perPageChange="perPageChange"
        @filterChange="showFilterModal = true"
        :defaultPerPage="perPage"
      />
      <table-lite
        :is-slot-mode="true"
        :is-loading="isLoading"
        :columns="columns"
        :rows="rows"
        :sortable="sortable"
        @do-search="setSort"
        @is-finished="isLoading = false"
      >
        <template v-slot:name="data">
          {{
            data.value.employee.givenName + " " + data.value.employee.familyName
          }}
        </template>
        <template v-slot:skill="data">
          {{ data.value.skill.skill }}
        </template>
        <template v-slot:skillNo="data">
          {{ data.value.skill.skillNo }}
        </template>
        <template v-slot:level="data">
          {{ data.value.level }}
        </template>
        <template v-slot:expiryDate="data">
          {{ $helper.formatDate(data.value.expiryDate) }}
        </template>
        <template v-slot:createdAt="data">
          {{ $helper.formatDate(data.value.createdAt) }}
        </template>
        <template v-slot:updatedAt="data">
          {{ $helper.formatDate(data.value.updatedAt) }}
        </template>
        <template v-slot:category="data">
          {{
            $helper.getEnumTranslation(
                "SKILL_CATEGORY",
                data.value.skill.category,
                $i18n.locale
            )
          }}
        </template>
        <template v-slot:actions="data">
          <div class="just-buttons">
            <edit-btn :path="$helper.getEditUrl('skill-matrix', data.value.id)" />
            <delete-btn @pressDelete="deleteSkillMatrix(data.value['@id'])" />
          </div>
        </template>
      </table-lite>
      <table-pagination
        v-model="currentPage"
        :total="pages"
        :per-page="perPage"
        @input="onChangePage"
      />
    </div>
  </div>
</template>

  <script>
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import TableActions from "@/components/Table/TableActions.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";

export default {
  name: "SkillMatrixUser",
  components: {
    TableLite,
    TablePagination,
    DeleteBtn,
    TableActions,
    EditBtn,
  },
  props: {
    employeeObject: { Object, required: true },
  },
  mixins: [ResourceUtils, ListUtils],
  data() {
    return {
      search: "",
      isLoading: false,
      perPage: this.$store.state.defaultPerPage,
      currentPage: 1,
      pages: 0,
      showFilterModal: false,
      sortable: {
        order: "skill",
        sort: "asc",
      },
      filterFields: [
        {
          label: "forms.createdAt",
          name: "createdAt",
          value: null,
          type: "range",
        },
        {
          label: "forms.level",
          name: "level",
          value: null,
          type: "text",
        },
        {
          label: "forms.employeeNo",
          name: "employee",
          value: null,
          type: "employee",
        },
        {
          label: "forms.skillNo",
          name: "skill.skillNo",
          value: null,
          type: "skill",
        },
        {
          label: "forms.category",
          name: "skill.category",
          value: null,
          type: "enum",
          enumName: "SKILL_CATEGORY",
        },
        {
          label: "forms.expiryDate",
          name: "expiryDate",
          value: null,
          type: "range",
        },
      ],
      advancedParams: [],
      columns: [
        {
          label: this.$t("forms.name"),
          field: "name",
          sortable: false,
        },
        {
          label: this.$t("forms.skillNo"),
          field: "skillNo",
          sortable: true,
        },
        {
          label: this.$t("forms.skill"),
          field: "skill",
          sortable: true,
        },
        {
          label: this.$t("forms.level"),
          field: "level",
          sortable: true,
        },
        {
          label: this.$t("forms.category"),
          field: "category",
          sortable: true,
        },
        {
          label: this.$t("forms.createdAt"),
          field: "createdAt",
          sortable: true,
        },
        {
          label: this.$t("forms.updatedAt"),
          field: "updatedAt",
          sortable: true,
        },
        {
          label: this.$t("forms.expiryDate"),
          field: "expiryDate",
          sortable: true,
        },
        {
          label: this.$t("forms.actions"),
          field: "actions",
          sortable: false,
          width: "80px",
        },
      ],
      rows: [],
      skills: [],
      skillsLevels: [],
    };
  },
  created() {
    this.load();
  },
  computed: {
    addLink() {
      const base = `skill-matrix/${this.employeeObject.id}`
      return `${this.$helper.getAddUrl(base)}`
    }
  },
  methods: {
    load() {
      if (this.employeeObject["@id"]) {
        const params = {
          itemsPerPage: this.perPage,
          page: this.currentPage,
          employee: this.employeeObject["@id"],
          level: ['Y','2','3','4']
        };
        params["order[" + this.sortable.order + "]"] = this.sortable.sort;
        this.$SkillMatrices
          .getCollection({ params }, "skill_matrix_list")
          .then((response) => {
            this.pages = response.data["hydra:totalItems"];
            this.rows = response.data["hydra:member"];
          });
      }
    },
    setSort(offset, limit, order, sort) {
      this.sortable.order = order;
      this.sortable.sort = sort;

      if (this.sortable.order === 'skill') {
        this.sortable.order = 'skill.skill'
      }

      if (this.sortable.order === 'skillNo') {
        this.sortable.order = 'skill.skillNo'
      }

      this.load();
    },
    inputChange(value) {
      this.currentPage = 1;
      this.search = value;
      this.load();
    },
    onChangePage(page) {
      this.currentPage = page;
      this.load();
    },
    deleteSkillMatrix(url) {
      this.deleteByUrl(
        this.$SkillMatrices,
        url,
        this.$t('messages.skillMatrixDeleted'),
        null,
        this.load,
        this.error
      );
    },
    onPageChange() {
      this.load();
    },
    perPageChange(num) {
      this.currentPage = 1;
      this.perPage = num;
      this.load();
    },
    error() {
      this.$emit("clearAction");
    },
    setAdvancedParams(data) {
      this.advancedParams = data;
      this.load();
    },
  },
};
</script>

